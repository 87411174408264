import { makeAutoObservable, runInAction } from "mobx";
import { debounce } from 'lodash';
import { history } from 'src/router';
import { fromDateTime, toDateTime, show, IUser, IPermissions, IPlace } from 'src/libs';
import api, { authToken } from 'src/services/api';
import ls from 'src/services/ls';

import JWT from 'jsonwebtoken';
import { searchParams } from 'src/utils';
import { t } from "i18next";
import i18n from "src/i18n/i18n";


const emptyPlace = {
  id: '',
  name: '',
  counter: 0,
  isLocality: false,
  lat: 0,
  lon: 0
}

export interface IListItem {
  id: number | string;
  title: string;
  desc: string;
}

interface IBirthDataOld {
  dateTime: {
    date: string | null;
    time: string | null;
    gmt: number | null;
  } | null,
  place: IPlace | null;
}

export interface ISession {
  id: number;
  info: {
    address: string;
    app: string;
    device: string;
  }
}

export default class UserStore implements IUser {
  id = -1
  userId: number = -1
  avatarUrl = '' //"https://images.pexels.com/photos/2128807/pexels-photo-2128807.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"
  email = ''
  firstName = ''
  lastName = ''
  // @ts-ignore
  gender = null
  birth = {
    dateTime: '',
    gmt: null,
    place: emptyPlace as IPlace, // FIXME: ???
  };
  astrologyLevel = 1
  phoneNumber = ''
  sessionId = 0
  // @ts-ignore
  permissions: IPermissions | undefined = undefined
  showWelcomeInstruments = false

  activeData() {

  }

  private _mutableEmail: string = ''
  private _mutablePhoneNumber: string = ''
  private _mutablePassword: string = ''

  get mutableEmail() {
    return this._mutableEmail
  }
  set mutableEmail(val: string) {
    this._mutableEmail = val
  }

  get mutablePhoneNumber() {
    return this._mutablePhoneNumber
  }
  set mutablePhoneNumber(val: string) {
    this._mutablePhoneNumber = val
  }

  get mutablePassword() {
    return this._mutablePassword
  }
  set mutablePassword(val: string) {
    this._mutablePassword = val
  }

  private _avatarData: string = '';

  get avatarData() {
    return this._avatarData;
  }

  set avatarData(data: string) {
    this._avatarData = data;
  }

  private _astrologyLevels: IListItem[] = [
    { id: 0, title: "chronos.app.settings.bedinning", desc: "chronos.profile.astrologyLevels.0.description" },
    { id: 1, title: "chronos.app.settings.amateur", desc: "chronos.profile.astrologyLevels.1.description" },
    { id: 2, title: "chronos.app.settings.professional", desc: "chronos.app.components.onboarding.welcomeInterface.level.value.2.subtitle" }
  ]
  

  get astrologyLevels() {
    return this._astrologyLevels;
  }

  private _sessions: ISession[] = []

  get userSessions() {
    return this._sessions.filter(({ id }) => id !== this.sessionId)
  }
  set userSessions(val: ISession[]) {
    this._sessions = val
  }

  get activeUserSession(): ISession {
    return this._sessions.find(({id}) => id === this.sessionId)!
  }
  
  private _removableSession: number | null = 0
  
  set removableSession(val: number | null) {
    this._removableSession = val
  }
  get removableSession() {
    return this._removableSession
  }

  setAstrologyLevel(level: number) {
    this.astrologyLevel = level;
    this._updateAstrologyLevel(level);
  }

  
  updateField(field: string, val: any) {
    runInAction(() => {
      (this as any)[field] = val;
    })
  }

  updateBirthDate(isoDateTime: string) {
    // debugger
    this.birth = this.birth || {}
    this.birth.dateTime = isoDateTime;
  }

  updateBirthPlace(place: IPlace) {
    this.birth.place = place;
  }

  get levelOfAstrologyName(): string | undefined {
    return this._astrologyLevels.find(({ id }) => this.astrologyLevel === id)?.title;
  }

  updateAstrologyLevel: (level: number) => void = debounce(async (level: number) => {
    api.updateInfo({ astrologyLevel: level })
      .then((result) => {
        console.log('update level ok -', result)
        show({ text: t("chronos.profile.astrologyLevels.levelSet"), type: 'success', timeout: 3000 });
      })
      .catch((err) => {
        console.log('update level err -', err)
        show({ text: t("chronos.profile.astrologyLevels.levelSetError"), type: 'error', timeout: 3000 });
        throw err;
      })
  }, 500)

  get fullName() {
    return `${this.firstName} ${this.lastName}`;
  }

  async auth() {
    const sp = searchParams();
    const token = sp.get('token') || '';
    
    if (token) {
      
      sp.delete('token');
      try {
        const login = await api.signIn(token);
        const spString = sp.toString();
        history.replace(`${location.pathname}${spString ? `?${spString}` : ''}`);
        authToken.setToken(login.jid);
        return login?.jid ? JWT.decode(login.jid) : {};
      } catch (err) {
        console.log('auth signIn err -', err);
        throw err;
      }
    } else {
      
      try {
        const refresh = await api.refreshToken();
        if (refresh?.jid) {
          // mainBackendApi.token = { access: refresh.jid, refresh: refresh.jid };
          authToken.setToken(refresh.jid);
          const language = await api.getLanguage(refresh.jid);
          language && i18n.changeLanguage(language);
        }
        
        return refresh?.jid ? JWT.decode(refresh.jid) : {};
        
      } catch (err) {
        console.log('auth refreshToken err -', err);
        throw err;
      }
    }
  } 

  async me() {
    try {
      const auth = await this.auth();
      const jid = authToken.getToken();

      if (jid) {
        const language = await api.getLanguage(jid);
        language && i18n.changeLanguage(language);
        
        auth.sessionId && runInAction(() => {
          this.sessionId = auth.sessionId
        })
      }
      
    } catch (e) {
      throw e;
    }
  }

  async logOut() {
    try {
      await api.signOut();
      ls.remove('token');
      api.goToAuth();
    } catch (err) {
      api.goToAuth();
    }
  }

  async dropAccount() {
    try {
      api.goToAuth();
      ls.remove('token');
    } catch (err) {
      api.goToAuth();
    }
  }

  async fetchPersonalData() {
    try {
      const userInfo = await api.getInfo();
      userInfo && this.setUser(userInfo);
      return userInfo;
    } catch (e) {
      console.log('user info err -', e);
      // throw e;
    }
    
  }

  async fetchUserSessions() {
    try {
      const sessions: ISession[] = await api.getUserSessions();
      if(sessions) {
        this.userSessions = sessions.map(({ id, info: { address, app, device }}) => {
          const client = app?.split(' ')?.[0]?.toLowerCase()
          return {
            id,
            info: {
              address,
              device,
              app: client
            }
          }
        });
      }
      return sessions;
    } catch (e) {
      console.log('fetch user sessions err -', e);
      // debugger
      throw e;
    }
  }

  setUser(user: {[key: string]: any}) {
    ({
      avatarUrl: this.avatarUrl,
      email: this.email,
      firstName: this.firstName,
      gender: this.gender,
      lastName: this.lastName,
      astrologyLevel: this.astrologyLevel,
      phoneNumber: this.phoneNumber
    } = user);

    this.id = Number(user.id || -1);
    this.userId = Number(user.userId || -1);
    user.sessionId && (this.sessionId = user.sessionId);
    user.permissions && (this.permissions = user.permissions);

    const { date = '', time = '' } = user.birth.dateTime || {};
    this.birth.dateTime = date ? fromDateTime(date, time) : '';

    this.birth.gmt = user.birth?.dateTime?.gmt || this.birth.gmt;

    this.birth.place = user.birth?.place || null;

    if (this.phoneNumber) {
      this._mutablePhoneNumber = this.phoneNumber;
    }
  }

  async updatePersonalData() {
    try {
      if (this._avatarData) {
        const avatar = await api.updateAvatar(this._avatarData)
        runInAction(() => {
          this.avatarUrl = avatar.avatarUrl
          this.avatarData = ''
        })
      } else if (!this.avatarUrl) {
        await api.deleteAvatar()
      }

      const birth: IBirthDataOld = {
        dateTime: {
          date: '',
          time: '',
          gmt: null
        },
        place: null
      }

      // если установлена дата и время рождения
      if (this.birth.dateTime) {
        const { date, time } = toDateTime(this.birth.dateTime);
        birth.dateTime!.date = date;
        birth.dateTime!.time = time;
        birth.dateTime!.gmt = this.birth.gmt;
      } else {
        birth.dateTime = null;
      }

      // если дата и время не установлены
      if (this.birth.place?.name) {
        birth.place = this.birth.place
      }

      
      const user = await api.updateInfo({
        avatarUrl: this.avatarUrl,
        firstName: this.firstName,
        birth: birth,
        gender: this.gender,
        astrologyLevel: this.astrologyLevel
      });
      return user;
    } catch (e) {
      console.log('update user err -', e)
      throw e;
    }
  }

  async getUserProfile() {
    try {
      const result = await api.getInfo()
      this.setUser(result)
    } catch (e) {
      console.log('fetch user info err -', e)
    }
  }

  private _workDb: any = null
  get workDb() {
    return this._workDb
  }
  async fetchWorkDb() {
    if(this.workDb) return
    try {
      const result = await api.getWorkDb([])
      this._workDb = result
    } catch (err) {
      console.log('fetchWorkDb err -', err)
    }
  }

  private _updateAstrologyLevel: (level: number) => void

  constructor() {
    makeAutoObservable(this)

    // this.fetchWorkDb()

    this._updateAstrologyLevel = debounce(async (level: number) => {
      api.updateInfo({ astrologyLevel: level })
        .then((result) => {
          show({ text: t("chronos.profile.astrologyLevels.levelSet"), type: 'success', timeout: 7000 });
        })
        .catch((err) => {
          show({ text: t("chronos.profile.astrologyLevels.levelSetError"), type: 'error', timeout: 7000 });
          throw err;
        })
    }, 500)

  }
}