import React, { Suspense } from "react";
import { render } from "react-dom";
import { observer } from "mobx-react";
import styled, { css, keyframes } from 'styled-components';
import { Router, Switch, Route, Redirect, NavLink } from "react-router-dom";
import { history, routes } from 'src/router';

import 'src/index.css';
import store from "src/store";

import Header from 'src/components/containers/Header';
import OuterContainer from 'src/components/containers/OuterContainer';
import InnerContainer from 'src/components/containers/InnerContainer';
import NavigationContainer from 'src/components/containers/NavigationContainer';
import PageContainer from 'src/components/containers/PageContainer';

// import Overlay from 'src/components/containers/Overlay';
// import AppOverlay from 'src/components/AppOverlay';

import AppsControl from 'src/components/AppsControl';
import Faq from 'src/components/Faq';
import UserProfile from 'src/components/UserProfile';
import Search from 'src/components/Search';
import Spinner from 'src/components/ui/Spinner';
import { Tooltip } from 'src/libs';
import Modal from 'src/components/containers/Modal';

import EmailConfirmForm from 'src/pages/Security/EmailConfirmForm';
import PhoneConfirmForm from 'src/pages/Security/PhoneConfirmForm';
import AccountDeleteAlert from 'src/pages/Security/AccountDeleteAlert';
import CloseSessionAlert from 'src/pages/Security/CloseSessionAlert';
import PasswordConfirmForm from 'src/pages/Security/PasswordConfirmForm';
import { useTranslation } from "./i18n/useTranslation";
import i18n from "./i18n/i18n";
import i18next, { changeLanguage } from "i18next";
import { accessOnlyForRu, s3urls } from "./utils";
import api, { authToken, mainBackendApi } from "./services/api";
import { device } from "./theme";

// FIXME: use mainBackendApi
async function getRegionByIp() {
  const result = await fetch(`${process.env.REACT_APP_BACKEND}/api/region`, {
    method: "GET",
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' }
  });

  const { data } = await result.json();
  return data;
}

const Main = observer(({
  store
}: {
  store: any
}) => {
  const [isInit, setIsInit] = React.useState(false);

  React.useEffect(() => {
    i18next.on('initialized', async () => {
      try {
        // читаем и сохраняем ссылку на приложение, с которого пришли
        store.ConfigStore.handleReferrer()

        // читаем auth-token и авторизуемся
        await store.UserStore.me()

        if (i18n.language === 'unknown') {
          try {
            const token = authToken.getToken()
            
            if (token) {
              let language = await api.getLanguage(authToken.getToken());

              if (!language) {
                language = (await mainBackendApi.getRegion()).language;
              }
            }
            
          } catch (e) {
            throw e;
          }
        }
        setIsInit(true);

        try{
          await store.UserStore.fetchPersonalData()

        } catch (e) {
          console.log('* TOP LEVEL fetchInfo err -', e)
        }

        await store.UserStore.fetchUserSessions()
        await store.SecurityStore.fetchSettingsConfig()
      } catch (e) {
        console.log(e);
      }
    });

    i18next.on('loaded', async () => setIsInit(i18n.language !== 'unknown'));
  }, [])

  const { t } = useTranslation();
  
  return isInit ? 
    <Router history={history}>
      <Header blurred={store.covered}>
        <OuterContainer>
          <AppsControl />
          <Faq onClick={() => { window.open(s3urls.support, '_blank') }} />
          <InnerContainer>
            <UserProfile
              visible={store.isLogin}
              onLogout={() => { store.UserStore.logOut() }}
              user={store.UserStore}
            />
            {store.isLogin && 
              <NavigationContainer>
                {
                  routes
                    .filter(({ disabled }) => !disabled)
                    .filter(({ onlyForRu }) => !(onlyForRu && !accessOnlyForRu()))
                    .map(({ path, title, Icon }, idx) => {
                      const stage_num = process.env.REACT_APP_BACKEND?.split('-')[2];
                      const altPath = { pathname: `https://${ stage_num  ? `pre-stage-${stage_num}-app` : 'app'}.chronos.mg/settings/payments` }
                      const isPaymentsPath = path === '/payments'
                      return idx <= 3 ?
                        <NavLink 
                          to={isPaymentsPath ? altPath : path}
                          target={isPaymentsPath ? "_blank" : "_self"}
                          activeClassName="active" 
                          key={path}
                        >
                          <Icon />{t(title)}
                        </NavLink>
                        : <Tooltip text={t("chronos.profile.title.inDeveloping")} color="blue" key={path}>
                            <NavLink to={path} activeClassName="active"><Icon />{t(title)}</NavLink>
                          </Tooltip>
                    })
                }
              </NavigationContainer>}
            {/* <Search /> */}
          </InnerContainer>
        </OuterContainer>
      </Header>
      <MainContainer isLogin={store.isLogin} blurred={store.covered}>
        <PageContainer loading={store.pageLoading}>
          <Suspense fallback={<Spinner />}>
            <Switch>
              <Redirect from="/" exact to={`${routes[0].path}`} />
              {routes.map(({ path, Component }) => <Route path={path} exact render={() => <Component store={store} />} key={path} />)}
            </Switch>
          </Suspense>
        </PageContainer>
      </MainContainer>
      <Modal visible={store.confirmEmailModalVisible}>
        <EmailConfirmForm onClose={() => { store.setConfirmEmailModalVisible(false) }} />
      </Modal>
      <Modal visible={store.confirmPhoneModalVisible}>
        <PhoneConfirmForm onClose={() => { store.setConfirmPhoneModalVisible(false) }} />
      </Modal>
      <Modal visible={store.accountDeletingModalVisible}>
        <AccountDeleteAlert />
      </Modal>
      <Modal visible={store.closeSessionsModalVisible}>
        <CloseSessionAlert />
      </Modal>

      <Modal visible={store.confirmChangePasswordModalVisible}>
        <PasswordConfirmForm onClose={() => store.setConfirmChangePasswordModalVisible(false) }/>
      </Modal>
      
    </Router>
    : <SpinnerWrapper><Spinner /></SpinnerWrapper>;
})


export const MainContainer = styled.main<{ isLogin: boolean; blurred: boolean; }>`
  position: relative;
  margin-top: 15.25rem;

  ${props => props.blurred && css`
    filter: blur(16px);
  `}

  transition: margin-top ease 0.2s;

  ${props => props.isLogin === false && css`
    margin-top: 6.75rem;
  `}

  @media ${device.mobileL} {
      margin-top: 13.5rem;
      /* padding-bottom: 1rem; */
  }
`

const animateShow = keyframes`
  from {
    opacity: 0;
    transform: translateY(30%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`

const SystemAlert = styled.div`
  position: fixed;
  width: 100vw;
  bottom: 2rem;
  text-align: center;
  animation: ${animateShow} 0.32s var(--easeOutQuart);
  z-index: 999;
`
export const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
`


render(<Main store={store} />, document.getElementById('root'));