import { searchParams } from 'src/utils';
import store from 'src/store';
import JWT from 'jsonwebtoken';
import { REACT_APP_BACKEND, REACT_APP_AUTH } from 'src/config';
import ls from './ls';

import { ChronosApi, WorkDatabaseKeys, IWorkProfileCardData, WORK_PROFILE_DOMAIN } from 'src/libs';
import i18n from 'src/i18n/i18n';

export const mainBackendApi = new ChronosApi(REACT_APP_BACKEND, REACT_APP_AUTH, undefined, `https://${process.env.NODE_ENV === 'development' ? 'dev-' : ''}places.chronos.mg` as string);

export class ApiError extends Error {
  private _errors: string[] = [];

  get errors() {
    return this._errors;
  }

  constructor(errors: string[]) {
    super(errors.join(";"));
    this._errors = errors;
  }
}

export const authToken = {
  _tokenKeyName: 'token',

  _getData(): String | undefined {
    let data: any = ls.get(this._tokenKeyName);
    if (data) {
      data = typeof data === "string" ? data : JSON.parse(data);
    }
    return data?.access || data
  },
  setToken(token: string) {
    ls.set(this._tokenKeyName, token);
  },

  getToken() {
    return this._getData() as any;
  }
}


class Api {
  private _host: string;

  constructor(host: string) {
    this._host = host;
  }

  private async query(method: string, uri: string, data?: any): Promise<any> {
    const headers: {
      [key: string]: string;
    } = {
      "Content-Type": "application/json;charset=utf-8",
      "Accept": 'application/json',
    };

    if (uri.includes('/login')) {
      headers['front-service-location'] = window.location.href;
    }

    return fetch(`${this._host}/api/${uri}`, {
      method,
      headers,
      mode: 'cors',
      credentials: 'include',
      body: ['POST', 'PUT', 'DELETE'].includes(method) && data ? JSON.stringify(data) : null
    })
      .then((response) => {
        // console.log('response - ', JSON.stringify(response))
        return response.ok ? response : Promise.reject(response)
      })
      .then(response => {
        return response.json();
      })
      .catch(async (response: Response) => {
        if (response.status === 401) {
          if (uri.includes('refresh')) {
            this.goToAuth();
          } else {
            this.refreshToken()
              .then((res) => {
                // ls.set('token', res.jid);
                authToken.setToken(res.jid);
                return this.query(method, uri, data);
              })
          }
        } else {
          console.log('request error - ', JSON.stringify(response))

          if (response.json)
            throw await response.json();
        }
      })
  }

  refreshToken() {
    return this.query('GET', 'settings/refresh');
  }

  verifyChannel(channel: string, token: string) {
    return this.query('POST', 'settings/channel/verify', { channel, token });
  }

  changeChannnel(channel: string, verification: string) {
    return this.query('PUT', 'settings/channel', { channel, verification });
  }

  changePassword(newPassword: string, confirmPassword: string) {
    return this.query('PUT', 'password', { newPassword, confirmPassword });
  }

  reLogin(password: string) {
    return this.query('PUT', 'settings/relogin', { password });
  }

  getUserSessions() {
    return this.query('GET', 'user/sessions');
  }

  closeAllSessions() {
    return this.query('DELETE', 'user/sessions/?all=true');
  }

  closeSession(id: number) {
    return this.query('DELETE', `user/sessions/?id=${id}`);
  }

  getReasonsForDeletion() {
    return this.query('POST', 'settings/config');
  }

  deleteMe(reason: string) {
    return this.query('DELETE', `user/?reason=${reason}`);
  }

  changeLanguage(token: string, language: string) {
    // FIXME: do it on the server side
    const { userId } = JWT.decode(token);
    if (!userId) return;
    return this.query('PUT', `/user/${userId}/language`, { language });
  }

  async getLanguage(token: string) {
    // FIXME: do it on the server side
    if (!token) return '';
    const { userId } = JWT.decode(token);
    if (!userId) return '';
    const resp = await this.query('GET', `user/${userId}/language`);
    return resp.data;
  }

  goToAuth() {
    const sp = searchParams();
    sp.delete("token");
    const spString = sp.toString();
    sp.append("ref", `${location.protocol}//${location.host}${location.pathname}${spString ? `?${spString}` : ""}`);
    location.href = `${REACT_APP_AUTH}/#/?${sp.toString()}`;
  }

  async signIn(token: string) {
    try {
      const result = await this.query('POST', 'settings/login', { token });
      return result;
    } catch (e) {
      throw e;
    }
  }

  async signOut() {
    try {
      const result = await this.query('POST', 'auth/logout', { sessionId: Number(store.UserStore.sessionId) });
      return result;
    } catch (e) {
      throw e;
    }
  }

  getConfig() {
    return this.query('POST', 'settings/config');
  }

  getInfo() {
    return this.query('GET', 'user/info');
  }

  updateInfo(info: {[key: string]: any}) {
    return this.query('PUT', 'user/info', info);
  }

  getWorkDb(keys: WorkDatabaseKeys[] = [], showHidden = false) {
    const q: string[] = [];
    if (keys.length) {
      q.push(`keys=${keys.join(',')}`);
    }
    if (showHidden) {
      q.push('showHidden');
    }
    return this.query('GET', `settings/work${q.length ? `?${q.join('&')}` : ''}`);
  }

  getWorkProfile(userId: number) {
    return this.query('GET', `settings/work/profiles`);
  }

  typeform(formId: string, id: string) {
    return this.query('GET', `settings/work/typeform`)
  }


  getAllCards() {
    return this.query('GET', 'settings/cards');
  }

  dropCard(cardId: string, domain: WORK_PROFILE_DOMAIN) {
    return this.query('POST', 'settings/drop/card/', { cardId, domain });
  }

  getCardLink(domain: WORK_PROFILE_DOMAIN, personalData?: Partial<IWorkProfileCardData>) {
    return this.query('POST', 'settings/cards', { cardData: personalData, domain });
  }

  updateAvatar(data: string) {
    return this.query('PUT', 'user/avatar', { base64: data });
  }

  deleteAvatar() {
    return this.query('DELETE', 'user/avatar');
  }
}

const api = new Api(REACT_APP_AUTH as string);

export default api;
